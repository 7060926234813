<template>
  <v-container align="center" justify="center">
    <v-row class="mx-auto py-4">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }"
            >Assessment center</router-link
          >
          | <span class="title"> Booking Checkout</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto" cols="12" md="12" xl="10">
        <v-card
          elevation="2"
          class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10 md:tw-overflow"
        >
          <div class="c-bg-blue tw--mt-4 tw-h-20">
            <p class="c-yellow-text tw-text-xl tw-ml-5 tw-pt-7">Checkout</p>
          </div>
          <v-card-title class="tw-mt-4">Checkout Preparation</v-card-title>
          <v-divider></v-divider>
          <div class="m-0 tw-my-5 tw-px-4">
            <p class="mb-0">
              Submit your client's checklist form to confirm the items that were
              in the vehicle at booking
            </p>
            <div
              class="tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-items-center"
            >
              <div>
                <span class="c-gray-text tw-font-bold mr-2"
                  >Insured’s Name</span
                >
                {{ booking.clientInfo.name }}
              </div>
              <div>
                <v-text-field
                  v-model="fuelLevel"
                  class="tw-w-1/2"
                  label="Fuel Gauge Level"
                  placeholder="e.g Quater, Half, Full, Empty"
                  required
                ></v-text-field>
              </div>
              <div>
                <span class="c-gray-text tw-font-bold mr-2"
                  >Registration No.</span
                >
                {{ booking.vehicleInfo.registrationNumber }}
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="tw-mt-5 tw-px-4">
            <h5 class="mb-3 tw-text-xl">Checkout Checklist</h5>
            <p class="mb-3">
              Please use the form below to verify the presence of items involved
              in the vehicle at booking. The left section serves as a checklist,
              indicating missing and available items, while the right section
              functions as a checkout list to confirm the condition and
              availability of each item before garage recieves the vehicle.
            </p>
          </div>
          <v-divider></v-divider>
          <div>
            <v-window v-model="step">
              <v-window-item :value="1">
                <Checkout
                  :step="step"
                  :getPartsByPage="getChecklistParts"
                  :getChecklistSelection="getChecklistSelection"
                  :getChecklistComments="getChecklistComments"
                  @back-page="back"
                  @forward-page="next"
                  @submit="submit"
                />
              </v-window-item>
              <v-window-item :value="2">
                <Checkout
                  :step="step"
                  :getPartsByPage="getChecklistParts"
                  :getChecklistSelection="getChecklistSelection"
                  :getChecklistComments="getChecklistComments"
                  @back-page="back"
                  @forward-page="next"
                  @submit="submit"
                />
              </v-window-item>
              <v-window-item :value="3">
                <Checkout
                  :step="step"
                  :getPartsByPage="getChecklistParts"
                  :getChecklistSelection="getChecklistSelection"
                  :getChecklistComments="getChecklistComments"
                  @back-page="back"
                  @forward-page="next"
                  @submit="submit"
                />
              </v-window-item>
              <v-window-item :value="4">
                <Checkout
                  :step="step"
                  :getPartsByPage="getChecklistParts"
                  :getChecklistSelection="getChecklistSelection"
                  :getChecklistComments="getChecklistComments"
                  @back-page="back"
                  @submit="submit"
                />
              </v-window-item>
            </v-window>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { formatStatus } from '../../../utils/helpers'

export default {
  components: {
    Checkout: () =>
      import('@/components/assessment-center/bids-submitted/checkout.vue'),
  },
  data() {
    return {
      step: 1,
      booking: {},
      fuelLevel: '',
      finalObject: {},
      commentValues: [],
      persistentPhotos: [], // To store photos across all pages
    }
  },
  computed: {
    getBookingId() {
      return this.$route.params.bookingId
    },
    getRegistration() {
      return this.$route.params.reg
    },
    getChecklist() {
      return this.booking.checklist
    },
    getPartsArray() {
      const keys = Object.keys(this.getChecklist)
      const PartKeys = keys.filter((item, i) => {
        if (!(i % 2)) {
          return item
        }
      })
      return PartKeys
    },
    getValuesArray() {
      const values = Object.values(this.getChecklist)
      let selectionValues = []
      values.filter((item) => {
        if (typeof item === 'boolean') {
          selectionValues.push(item)
        } else {
          this.commentValues.push(item)
        }
      })
      return selectionValues
    },
    getChecklistParts() {
      const increment = 20
      const start = (this.step - 1) * increment
      const end = start + increment
      const parts = this.getPartsArray.slice(start, end)
      return parts
    },
    getChecklistSelection() {
      const increment = 20
      const start = (this.step - 1) * increment
      const end = start + increment
      const values = this.getValuesArray
        .slice(start, end)
        .map((item) => item.toString())
      return values
    },
    getChecklistComments() {
      const increment = 20
      const start = (this.step - 1) * increment
      const end = start + increment
      const comment = this.commentValues.slice(start, end)
      return comment
    },
  },
  methods: {
    submit(values) {
      this.createObject(values) // Finalize the object with the last page's data

      axios
        .post(
          `/motor-assessment/api/checkoutList/${this.getBookingId}/${this.$store.state.auth.user.sub}`,
          this.finalObject
        )
        .then((res) => {
          if (res.data.success) {
            this.$toasted.success(res.data.msg, {
              duration: 3000,
            })
            this.$router.go(-1) // Navigate back
          } else {
            this.$toasted.error(res.data.msg, {
              duration: 3000,
            })
          }
        })
        .catch((error) => {
          this.$toasted.error(error.message, {
            duration: 3000,
          })
        })
    },
    createObject(values) {
      let selectionArray = values[0] || [] // Default to empty array
      let commentsArray = values[1] || [] // Default to empty array
      let photosArray = values[2] || [] // Default to empty array

      // Create an object for selections
      let checkoutObj = Object.fromEntries(
        this.getChecklistParts.map((key, index) => [
          key,
          selectionArray[index] || '',
        ])
      )

      // Create an object for comments
      let commentObj = Object.fromEntries(
        this.getChecklistParts.map((key, index) => [
          `${key}_comment`,
          commentsArray[index] ? commentsArray[index] : '',
        ])
      )

      // Merge photos for each label into `persistentPhotos`
      photosArray.forEach((photo) => {
        const existingEntry = this.persistentPhotos.find(
          (entry) => entry.label === photo.label
        )
        if (existingEntry) {
          existingEntry.photos = [...existingEntry.photos, photo]
        } else {
          this.persistentPhotos.push({
            label: photo.label,
            photos: [photo], // Initialize as an array to allow multiple photos per label
          })
        }
      })

      // Flatten `persistentPhotos` for submission
      let flattenedPhotos = this.persistentPhotos.flatMap((entry) =>
        entry.photos.map((photo) => ({
          label: entry.label,
          comment: photo.comment,
          image_url: photo.image_url,
          image_base_64: photo.image_base_64,
          available: photo.available,
        }))
      )

      // Construct the final object incrementally
      this.finalObject = {
        ...this.finalObject,
        ...checkoutObj,
        ...commentObj,
        checkout_list_photos: flattenedPhotos,
      }
    },

    next(values) {
      this.createObject(values)
      this.step++
    },
    back() {
      this.step--
    },
    async getBooking() {
      await axios
        .get(
          `/motor-assessment/api/booking/checkout/all?index=1&size=10&searchText=${this.getRegistration}`
        )
        .then((res) => {
          this.booking = res.data.data[0]
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
        })
    },
  },
  mounted() {
    this.getBooking()
  },
  filters: {
    formatStatus,
  },
}
</script>
